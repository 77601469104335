<template>
  <div class="flex fww mt-15 bg-fff jc-sa">
    <li
      class="item mb-15 mt-15"
      v-for="(item, index) in productList"
      :key="index"
      :item="item"
    >
      <router-link :to="'/product/detail/' + item.productId">
        <img class="img" :src="item.imgUrl" alt="暂无图片" />
        <div class="mt-10">
          <p class="f14 text-overflow">{{ item.masterName }}</p>
          <p class="mb-5 f14 text-overflow">{{ item.slaveName }}</p>
          <div class="flex jc-sb">
            <p>
              <span class="f10 red">￥</span
              ><span class="f16 red">{{ item.maxPrice }}</span>
            </p>
            <p>
              <span class="f10 f999">销量：</span
              ><span class="f999 f14 pr-5">{{ item.inventory }}</span>
            </p>
          </div>
        </div>
      </router-link>
    </li>
    <li class="item fix"></li>
  </div>
</template>

<script>
export default {
  props: ["productList"],
};
</script>

<style>

</style>