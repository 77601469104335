<template>
  <div class="index-list">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <!-- 轮播图 -->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in BannersList" :key="index">
          <img :src="item" alt="" />
        </van-swipe-item>
      </van-swipe>
      <!-- 商品分类 -->
      <div class="mt-20">
        <div class="flex jc-sa bg-fff fww mb-10">
          <router-link
            title="商品总金额"
            :to="{ path: '/type', query: { index: index } }"
            class="flex fdc aic pb-10"
            style="width: 25%"
            v-for="(item, index) in goodsType"
            :key="index"
          >
            <img :src="item.iconUrl" alt="暂无图片" />
            <span>{{ item.name }}</span>
          </router-link>
        </div>
      </div>
      <!-- 拼团,秒杀 -->
      <div class="pintuan flex mt-20 jc-sa">
        <a href="#" class="flex jc-sa rel">
          <span class="abs title">秒杀</span>
          <div class="flex fdc jc-c">
            <p class="f14">秒杀</p>
            <p class="f14 two">超值惊喜</p>
            <p class="three">立即砍价</p>
          </div>
          <img
            src="http://fresh.huruqing.cn/img/miaosha-icon.e7d1fb11.png"
            alt=""
            class="img"
          />
        </a>
        <a href="#" class="flex jc-sa rel">
          <span class="abs title">拼团</span>
          <div class="flex fdc jc-c">
            <p class="f14">拼团</p>
            <p class="f14 two">限时拼团</p>
            <p class="three">一键拼团</p>
          </div>
          <img
            src="http://fresh.huruqing.cn/img/pintuan-icon.711363b5.png"
            alt=""
            class="img"
          />
        </a>
      </div>
      <a href="#" class="coupon flex jc-sb aic">
        <p class="one">
          <i><van-icon name="coupon-o" size="18" /></i>
          <span>超值福利</span>
          <span class="ml-5 mr-5">|</span>
          <span>全场满减</span>
        </p>
        <p class="two">立即领取<van-icon name="arrow" /></p>
      </a>

      <div class="mt-10">
        <van-sticky>
          <ul class="navagater bg-fff flex jc-sa aic f14">
            <li class="mt-2">推荐</li>
            <li class="flex pt-2 aic">
              <span class="mr-2">销量</span>
              <div class="sales flex fdc">
                <van-icon name="arrow-up" />
                <van-icon name="arrow-down" />
              </div>
            </li>
            <li class="flex pt-2 aic">
              <span class="mr-5">价格</span>
              <div class="sales flex fdc">
                <van-icon name="arrow-up" />
                <van-icon name="arrow-down" />
              </div>
            </li>
          </ul>
        </van-sticky>
      </div>
      <!-- 商品列表 -->
      <van-list
        class="product_list"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getProductList"
      >
        <Goods :productList="productList" />
      </van-list>
    </van-pull-refresh>
    <div class="flex aic fdc"> 
        <p class="f10"><a href="https://beian.miit.gov.cn/" target="_blank">备案号:</a></p>
        <p class="f10"><a href="https://beian.miit.gov.cn/" target="_blank">赣ICP备2021009064号</a></p>
      </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import $http from "@/utils/http";
import Goods from "./Goods.vue";
export default {
  components: {
    Footer,
    Goods,
  },
  data() {
    return {
      //商品列表
      productList: [],
      //轮播图
      BannersList: [],
      //商品分类
      goodsType: [],
      refreshing: false,
      currPage: 1, //当前页
      finished: false,
      loading: false,
      //滚动位置
      scrollHeight: 0,
    };
  },
  //实例创建完毕
  created() {
    // this.getProductList();
    this.getBanner();
    this.getCategory();
  },
  //实例挂载之后
  mounted() {
    window.addEventListener(
      "scroll",
      () => {
        // 保存滚动条位置
        if (window.scrollY > 0) {
          this.scrollHeight = window.scrollY;
        }
      },
      false
    );
  },
  // 进入组件
  activated() {
    // 滚动到最初的位置
    setTimeout(() => {
      window.scrollTo(0, this.scrollHeight);
    }, 0);
  },
  methods: {
    //下拉刷新
    onRefresh() {
      let params = {
        currPage: 1,
      };
      //发请求,获取商品列表
      $http.get("/product/list", { params }).then((res) => {
        this.currPage = 2;
        this.refreshing = false;
        this.productList = res.list;
      });
    },
    //获取轮播图
    getBanner() {
      $http.get("product/getBanners").then((res) => {
        this.BannersList = res.banners;
      });
    },
    //发请求,获取商品列表
    getProductList() {
      //let url = `/product/list?currPage=${this.currPage}`;
      let params = {
        currPage: this.currPage,
      };

      $http.get("/product/list", { params }).then((res) => {
        this.currPage++; //当前页 + 1
        console.log(this.currPage);
        //将每页的数据合并为一个数组并重新赋值
        this.productList = [...this.productList, ...res.list];
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (res.list.length == 0) {
          this.finished = true; //加载完成后,不在触发 load 事件
        }
      });
    },
    //获取所有的分类
    getCategory() {
      $http.get("/category/all").then((res) => {
        this.goodsType = res.list;
      });
    },
  },
};
</script>

<style lang="less">
.index-list {
  background: #f4f4f4;
  font-size: 16px !important;
  min-height: 100vh;
}
.product_list {
  margin-bottom: 50px;
  .fix {
    margin-top: 0px;
    padding: 0px;
    height: 0px;
  }
}
.my-swipe {
  height: 210px;
  .van-swipe-item {
    color: #fff;
    //font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
  img {
    width: 100%;
  }
}
.red {
  color: #c03131;
}
.f999 {
  color: #999;
}
.pintuan {
  .title {
    right: 3px;
    color: #fff;
    font-size: 10px;
  }
  .img {
    height: 70px;
    width: 70px;
    margin-top: 12px;
  }
  .two {
    padding-bottom: 5px;
    color: #999;
  }
  .three {
    font-size: 13px;
    background-color: #d92c01;
    border-radius: 3px;
    color: #fff;
  }
  a {
    width: 170px;
    height: 100px;
    background: url(../../../assets/imgs/miaosha-bg.png) no-repeat;
    background-size: 100%;
  }
}
.coupon {
  background: url("../../../assets/imgs/coupon.png") no-repeat;
  background-size: 96%;
  height: 45px;
  background-position: 10px 0px;
  padding: 0px, 5px;
  color: #fff;
  .one {
    margin-left: 12px;
    font-size: 14px;
    line-height: 45px;
    i {
      padding: 0px 5px;
    }
  }
  .two {
    margin-right: 10px;
    font-size: 14px;
  }
}
.navagater {
  border-radius: 0px, 15px;
  border-bottom: 1px solid #ededed;
  height: 40px;
  .van-icon-arrow-up:before {
    content: "\e65f";
  }
  .sales {
    transform: scale(0.6);
  }
}
.product_list {
  .item {
    width: 165px;
  }
  .img {
    height: 165px;
    background: #f4f4f4;
  }
}

.more_data {
  color: #969799;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
}
.text-overflow {
  //display: block; /*内联对象需加*/
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
  text-overflow: ellipsis; /* 当对象内文本溢出时显示省略标记(...) ；需与overflow:hidden;一起使用。*/
}
</style>
