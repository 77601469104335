<template>
  <div class="footer bg-fff flex jc-sa fixed aic">
    <router-link to="/product" active-class="red" class="item flex fdc aic">
      <van-icon name="wap-home-o" size="20" />
      <span class="f14">首页</span>
    </router-link>
    <router-link active-class="red" to="/type" class="item flex fdc aic">
      <van-icon name="qr" size="20" />
      <span class="f14">分类</span>
    </router-link>
    <router-link active-class="red" to="/cart" class="item flex fdc aic">
      <van-icon name="shopping-cart-o" size="20" />
      <span class="f14">购物车</span>
    </router-link>
    <router-link active-class="red" to="/my" class="item flex fdc aic">
      <van-icon name="manager-o" size="20" />
      <span class="f14">我的</span>
    </router-link>
    <!-- <router-link active-class="red" to="/demo" class="item flex fdc aic">
      <van-icon name="wap-home-o" size="20" />
      <span class="f14">demo</span>
    </router-link>
    <van-button round type="info" @click="daka">打卡</van-button> -->
  </div>
</template>
<style lang="less" scoped>
.red {
  color: red;
}
.footer {
  bottom: 0px;
  width: 100%;
  height: 50px;
  border: 1px solid wheat;
}
</style>
<script>
import $http from "@/utils/http";
export default {
  methods: {
    daka() {
      $http
        .post("/sign/daka", { username: "南山艾建凌", finished: true })
        .then((res) => {
          alert(res.msg);
          console.log(res);
        });
    },
  },
};
</script>
